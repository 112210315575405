import { mapActions, mapGetters } from 'vuex'
import { showVueToast } from '@/utils'
import portfolio from '../../../constant/portfolio'
export default {
  name: 'corporate-training',
  data: () => ({
    payloads: {
      companyName: '',
      fullName: '',
      email: '',
      phoneNumber: ''
    },
    trainingProgram: '',
    message: '',
    category: 'CORPORATE',
    errors: [],
    phoneNumberInputType: 'number',
    isButtonDisabled: true,
    trainingProgramOptions: [
      { id: 'DATA_TRAINING', name: 'Data training' },
      { id: 'PRODUCT_DEVELOPMENT', name: 'Product development' },
      { id: 'MOBILE_DEVELOPMENT', name: 'Mobile Development' },
      { id: 'OTHERS', name: 'Others' }
    ],
    whyLearn: [
      {
        title: 'Accelerating Company Productivity',
        description: 'Elevate company productivity to achieve goals more quickly and effectively by improving employee capability.'
      },
      {
        title: 'Develop High-Performing Employees',
        description: 'Our tailored employee development programs help organizations unlock the potential of their employees.'
      },
      {
        title: 'Improve Cross Functional Teamwork',
        description: 'Building new skills to skills they need to work more effectively across departments and functions.'
      },
      {
        title: 'Fully Customizable Curriculum',
        description: 'With a proven track record of success in designing and delivering customized training programs, we will ensure the training is relevant, engaging, and effective.'
      }
    ],
    partnersOptions: {
      type: 'slide',
      autoplay: false,
      pagination: false,
      arrows: false,
      keyboard: false,
      perPage: 4,
      drug: true,
      speed: 1000,
      interval: 5000,
      gap: '1rem',
      lazyLoad: 'nearby',
      padding: { left: 2, right: 2 },
      breakpoints: {
        767: {
          perPage: 1,
          pagination: true
        }
      }
    },
    partners: ['astrapay', 'gotoko', 'btpns', 'bri', 'telkomsat', 'best', 'jet-brains', 'smartfren'],
    videoLink: 'https://www.youtube.com/watch?v=fuXrwwGhUes&t=1s',
    trainingOptions: {
      type: 'slide',
      autoplay: false,
      pagination: false,
      arrows: false,
      keyboard: false,
      perPage: 3,
      drug: true,
      speed: 1000,
      interval: 5000,
      gap: '1rem',
      lazyLoad: 'nearby',
      padding: { left: 2, right: 2 },
      breakpoints: {
        767: {
          perPage: 1,
          pagination: true
        }
      }
    },
    trainingList: [
      {
        image: require('@/assets/images/project/DataTraining.webp'),
        trainingName: 'Data Training',
        description: 'Unleashing the power of data through comprehensive, industry-leading data training.',
        detail: 'Data storage & management, data processing & analysis, data pipeline, data visualization.'
      },
      {
        image: require('@/assets/images/project/ProductDevelopment.webp'),
        trainingName: 'Product Development',
        description: 'Bringing your products to life through extensive product development training.',
        detail: 'Front end development, back end development, product management, UI/UX design.'
      },
      {
        image: require('@/assets/images/project/MobileDevelopment.webp'),
        trainingName: 'Mobile Development',
        description: 'Bringing your products to life through intensive mobile application development training.',
        detail: 'Android development, iOS development, application framework.'
      }
    ],
    trainingCategories: [
      'Application Development',
      'Website Development',
      'Mobile Development',
      'Data Engineering',
      'Data Science',
      'Data Analysis',
      'Product Design',
      'Product Management',
      'Game Development',
      'DevOps Training',
      'SysOps Training'
    ],
    currentPortofolioPage: 1,
    pageSize: 6,
    isVisibleDetail: false,
    selectedIndex: 0,
    selectedPortfolio: {}
  }),
  components: {
    School: () => import(/* webpackChunkName: "school" */ '@/components/icons/School'),
    CodeSlash: () => import(/* webpackChunkName: "code-slash" */ '@/components/icons/CodeSlash'),
    People: () => import(/* webpackChunkName: "people" */ '@/components/icons/People'),
    Person: () => import(/* webpackChunkName: "person" */ '@/components/icons/Person'),
    Dashboard: () => import(/* webpackChunkName: "Dashboard" */ '@/components/icons/Dashboard'),
    Map: () => import(/* webpackChunkName: "Map" */ '@/components/icons/Map'),
    Carousel: () => import(/* webpackChunkName: "carousel" */ '@/components/carousel/Carousel'),
    ProductCard: () => import(/* webpackChunkName: "image" */ '@/components/card/ProductCard'),
    ImageItem: () => import(/* webpackChunkName: "image" */ '@/components/image/ImageItem'),
    TextField: () => import(/* webpackChunkName: "icon" */ '@/components/forms/TextField'),
    Dropdown: () => import(/* webpackChunkName: "DropDown" */ '@/components/dropdown/Dropdown'),
    Button: () => import(/* webpackChunkName: "button" */ '@/components/button/Button'),
    Modal: () => import(/* webpackChunkName: "modal" */ '@/components/modal/Modal')
  },
  metaInfo() {
    return {
      title: this.$route.meta.title,
      meta: this.$route.meta.metaTags
    }
  },
  computed: {
    ...mapGetters('common', ['isMobile']),
    trainingPortfolio() {
      return portfolio.filter((item) => item.type === 'training')
    },
    portfolioList() {
      return this.trainingPortfolio.slice(0, this.currentPortofolioPage * this.pageSize)
    },
    totalPortfolio() {
      return this.trainingPortfolio.length
    }
  },
  watch: {
    payloads: {
      handler(value) {
        // eslint-disable-next-line no-useless-escape
        const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,20})+$/
        if (value.companyName === '') {
          this.errors['companyName'] = 'Company name cant be empty'
        } else {
          this.errors['companyName'] = ''
        }

        if (value.fullName === '') {
          this.errors['fullName'] = 'PIC name cant be empty'
        } else {
          this.errors['fullName'] = ''
        }

        if (value.email === '') {
          this.errors['email'] = 'Email cant be empty'
        } else if (!regex.test(value.email)) {
          this.errors['email'] = 'Email address format is invalid'
        } else {
          this.errors['email'] = ''
        }

        if (value.phoneNumber.length > 15) {
          this.payloads.phoneNumber = value.phoneNumber.slice(0, 15)
          this.phoneNumberInputType = 'text'
        } else {
          this.phoneNumberInputType = 'number'
        }

        if (value.phoneNumber === '') {
          this.errors['phoneNumber'] = 'Phone number cant be empty'
        } else if (value.phoneNumber[0] !== '0' || value.phoneNumber[1] !== '8') {
          this.errors['phoneNumber'] = 'Must start with 08xxxx'
        } else if (value.phoneNumber.length < 10) {
          this.errors['phoneNumber'] = 'Minimum 10 digits, Maximum 15 digits'
        } else {
          this.errors['phoneNumber'] = ''
        }
        this.updateIsButtonDisabled()
      },
      deep: true
    },
    trainingProgram() {
      if (this.trainingProgram === '') {
        this.errors['trainingProgram'] = 'Training program cant be empty'
      } else {
        this.errors['trainingProgram'] = ''
      }
      if (this.trainingProgram && this.trainingProgram.id === 'OTHERS' && this.message === '') {
        this.errors['message'] = 'Message cant be empty'
      } else {
        this.errors['message'] = ''
      }
      this.updateIsButtonDisabled()
    },
    message() {
      if (this.trainingProgram && this.trainingProgram.id === 'OTHERS' && this.message === '') {
        this.errors['message'] = 'Message cant be empty'
      } else {
        this.errors['message'] = ''
      }
      this.updateIsButtonDisabled()
    }
  },
  methods: {
    ...mapActions('common', ['showLoading', 'hideLoading']),
    ...mapActions('contact', ['contactUsWithCategory']),
    toggleVisibleDetail(value) {
      this.selectedPortfolio = value || {}
      this.isVisibleDetail = !this.isVisibleDetail
    },
    loadMore() {
      if (this.portfolioList.length !== this.totalPortfolio) {
        this.currentPortofolioPage++
      }
    },
    submitForm() {
      this.showLoading()
      this.contactUsWithCategory({
        payloads: {
          companyName: this.payloads.companyName,
          email: this.payloads.email,
          fullName: this.payloads.fullName,
          message: this.message,
          phoneNumber: this.payloads.phoneNumber,
          trainingProgram: this.trainingProgram.id,
          category: this.category
        }
      }).then(
        async () => {
          this.payloads.companyName = await ''
          this.payloads.fullName = await ''
          this.payloads.email = await ''
          this.payloads.phoneNumber = await ''
          this.trainingProgram = await ''
          this.message = await ''
          this.errors = []
          showVueToast('Sending form successfully!', 'success', 3000)
        },
        () => showVueToast('Failed to sending form, please try again later!', 'error', 3000)
      )
      setTimeout(() => this.hideLoading(), 3500)
    },
    updateIsButtonDisabled() {
      if (
        this.errors['companyName'] === '' &&
        this.errors['fullName'] === '' &&
        this.errors['email'] === '' &&
        this.errors['phoneNumber'] === '' &&
        this.errors['trainingProgram'] === '' &&
        (this.errors['message'] === undefined || this.errors['message'] === '')
      ) {
        this.isButtonDisabled = false
      } else {
        this.isButtonDisabled = true
      }
    },
    toContactUs() {
      this.$router.push({ name: 'Contact Us' })
    },
    toTermsAndConditions() {
      this.$router.push({ name: 'Terms and Conditions' })
    },
    toPartner() {
      this.$router.push({
        path: '/register-alliance',
        query: {
          assign: 'MANPOWER'
        }
      })
    },
    toSolution(item) {
      this.$router.push(item)
    }
  }
}
